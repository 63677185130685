import { WAYTOPAY_TF } from "data/booking"

export const initValuesFormCheckout = (data) => {
    return {
        email: "",
        passengers: new Array(data?.guestAdults).fill({
            name: "",
            lastName: "",
            birthDate: "",
            nationality: 0,
            gender: "",
            documentType: 0,
            documentNumber: "",
            birthDay: "",
            birthMonth: "",
            birthYear: "",
            email: "",
        }),
        wayToPay: WAYTOPAY_TF,
        payment: {
            offline: {
                cardNumber: "",
                expirationYear: new Date().getFullYear(),
                expirationMonth: new Date().getMonth() + 1,
                securityCode: "",
                name: "",
                lastName: "",
                documentNumber: "",
                phone: "",
                email: "",
            },
            transfer: {
                accountId: 10,
                amount: data.total,
            },
        },
    }
}