import { instaceApiBiblical } from "./config";

export const getChurchSettings = async (data) => {
    const result = await instaceApiBiblical().post("church/setting/get", data);
    if (result.status === 200) {
        return {
            ...result.data.data[0],
        }
    }
    return result.data
}