import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getCitiesByText } from 'services/city';

const useCities = () => {
    const [filter, setFilter] = useState('');

    const { data: cities } = useQuery({
        enabled: filter.length >= 3,
        queryKey: ['getCities', filter],
        queryFn: () => { return getCitiesByText(filter) }
    })

    return {
        cities,
        setFilter
    }
}

export default useCities;
