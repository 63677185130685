import instanceApi, { getToken } from "./config";

export const getCountries = async () => {
  const url = `${process.env.REACT_APP_API_URL}Country/ES`;
  let token = undefined;
  if (
    localStorage.getItem("tokenCh") !== null &&
    localStorage.getItem("tokenCh") !== ""
  ) {
    token = localStorage.getItem("tokenCh");
  } else {
    token = await getToken();
  }

  const result = await instanceApi(token).get(url);
  return result.data;
};
