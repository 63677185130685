import Label from "components/Label/Label";
import { ErrorMessage, useFormikContext } from "formik";
import UseCountry from "hooks/useCountry";
import moment from "moment";
import React, { FC } from "react";
import { Error } from "shared/Error";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";

export interface Props {
  index: number;
  props: {
    handleChange: any;
    handleBlur: any;
  };
  passenger: {
    name: string;
    lastName: string;
    birthDate: any;
    nationality: any;
    gender: any;
    documentType: any;
    documentNumber: number;
    email: string;
  };
}

const PeopleForm: FC<Props> = ({ index, props, passenger }) => {
  const { setFieldValue } = useFormikContext();
  const { countries } = UseCountry();
  return (
    <div
      style={{ display: "flex", flexWrap: "wrap" }}
      className="grid grid-flow-col auto-cols-max md:auto-cols-min"
    >
      <h5
        className="text-2xl font-semibold"
        style={{
          flexDirection: "column",
          padding: "5px",
          width: "100%",
          marginBottom: "15px",
        }}
      >
        Pasajero - {index + 1}
      </h5>
      <div style={{ flexDirection: "column", padding: "5px", width: "50%" }}>
        <Label>Nombres</Label>
        <Input
          type="text"
          name={`passengers.${index}.name`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={passenger.name}
        />
        <ErrorMessage name={`passengers[${index}].name`} render={Error} />
      </div>
      <div style={{ flexDirection: "column", padding: "5px", width: "50%" }}>
        <Label>Apellidos</Label>
        <Input
          type="text"
          name={`passengers.${index}.lastName`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={passenger.lastName}
        />
        <ErrorMessage name={`passengers[${index}].lastName`} render={Error} />
      </div>
      <div style={{ flexDirection: "column", padding: "5px", width: "50%" }}>
        <Label>Fecha de nacimiento</Label>
        <Input
          className="mt-1.5"
          type="date"
          defaultValue="YYYY-MM-DD"
          onChange={(e) => {
            const date = moment(e.target.value);
            setFieldValue(`passengers[${index}].birthDay`, date.date());
            setFieldValue(`passengers[${index}].birthMonth`, date.month() + 1);
            setFieldValue(`passengers[${index}].birthYear`, date.year());
            setFieldValue(`passengers[${index}].birthDate`, date);
          }}
        />
        <ErrorMessage name={`passengers[${index}].birthDate`} render={Error} />
      </div>
      <div style={{ flexDirection: "column", padding: "5px", width: "50%" }}>
        <Label>Nacionalidad</Label>
        <Select
          name={`passengers.${index}.nationality`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          defaultValue={passenger.nationality}
        >
          {countries?.map((country: any) => (
            <option value={country.id}>{country.name}</option>
          ))}
        </Select>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "5px",
          width: "50%",
        }}
      >
        <div className="flex items-center">
          <input
            defaultChecked={false}
            type="radio"
            name={`passengers.${index}.gender`}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={"Female"}
            className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
          />
          <label
            htmlFor={"gender"}
            className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
          >
            Femenino
          </label>
        </div>
        <div className="flex items-center">
          <input
            defaultChecked={false}
            type="radio"
            name={`passengers.${index}.gender`}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={"Masculino"}
            className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
          />
          <label
            htmlFor={"gender"}
            className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
          >
            Masculino
          </label>
        </div>

        <ErrorMessage name={`passengers[${index}].gender`} render={Error} />
      </div>
      <div style={{ flexDirection: "column", padding: "5px", width: "50%" }}>
        <Label>Tipo documento</Label>
        <Select
          name={`passengers.${index}.documentType`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          defaultValue={passenger.documentType}
        >
          <option value={0}>Cedula</option>
          <option value={1}>Passport</option>
        </Select>
      </div>
      <div
        className="space-y-2"
        style={{ flexDirection: "column", padding: "5px", width: "50%" }}
      >
        <Label>Número documento</Label>
        <Input
          type="text"
          name={`passengers.${index}.documentNumber`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={passenger.documentNumber}
        />
        <ErrorMessage
          name={`passengers[${index}].documentNumber`}
          render={Error}
        />
      </div>
      <div
        className="space-y-2"
        style={{
          flexDirection: "column",
          padding: "5px",
          width: "50%",
        }}
      >
        <Label>Correo electrónico</Label>
        <Input
          type="email"
          name={`passengers.${index}.email`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={passenger.email}
        />
        <ErrorMessage name={`passengers.${index}.email`} render={Error} />
      </div>
      <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
    </div>
  );
};

export default PeopleForm;
