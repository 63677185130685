import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { postBooking } from "services/booking";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const UseBooking = () => {
  const navigate = useNavigate();
  const createBooking = useMutation({
    mutationFn: postBooking,
    onError: (error) => {
      Toast.fire({
        icon: "error",
        title: error,
      });
      console.log(error);
    },
    onSuccess: (data, variables) => {
      const state = {
        ...data,
        dateStart: variables.bookingDetail[0].detail.dateStart,
        wayToPayId: variables.bookingDetail[0].wayToPayId,
        name: variables.name
      };
      console.log(state);
      navigate("/pay-done", { state });
    },
  });
  return {
    createBooking,
  };
};

export default UseBooking;
