export const reqBooking = (values, payment, data, wayToPayId) => {


    const passengersData = values.passengers.map((passenger) => {
        return {
            ...passenger,
            destinationId: 0,
            passengerType: 1,
            postalCode: "00000",
            address: "0",
            country: 50,
            state: 912,
            city: 2200,
        };
    });

    return {
        name: data.data.name,
        bookingId: 0,
        contactId: 475267, // 136303
        itaCode: 1430,
        countryCode: "USA",
        emailVoucher: values.email,
        confirmEmailVoucher: values.email,
        emergencyName: `${values.passengers[0].name} ${values.passengers[0].lastName}`,
        emergencyCountryCode: "USA",
        emergencyPhoneType: 1,
        emergencyPhone: "000000",
        language: "EN",
        applicationId: 12,
        createdBy: "Biblical Horizons Travel",
        bookingDetail: [
            {
                productId: 4, //Quemado 
                supplierId: "188", //Quemado 
                bookingSourceId: 25, //Quemado update 
                createdBy: "Biblical Horizons Travel", //Quemado 
                reserved: false, //Quemado 
                payment,
                currencyCode: "USD", //Quemado 
                detail: {
                    idPackage: 542, //Quemado 
                    packageCode: 542, //Quemado 
                    idPackEntry: 87566, //Quemado 
                    idExtraService: 0, //Quemado 
                    type: "1", //Quemado 
                    idCity: data.data.city.Id,
                    dateStart: data?.data?.detail[0]?.StartDate,
                    dateEnd: data?.data?.detail[0]?.EndDate,
                    BookingPassengers: passengersData,
                },
                benefitId: 0, //Quemado 
                planId: 0, //Quemado 
                value: data.total,
                fee: 0, //Quemado 
                tax: 0, //Quemado 
                merchant: 0, //Quemado 
                feecustomized: 0, //Quemado 
                discountIn: 0, //Quemado 
                discount: 0, //Quemado 
                othervalue: 0, //Quemado 
                total: data.total,
                wayToPayId,
            },
        ],
    }
}
