/* eslint-disable @typescript-eslint/no-unused-vars */
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import Loading from "shared/Loader";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getChurchSettings } from "services/churchSetting";
import Swal from "sweetalert2";
import { useAuth } from "context/auth";

export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const { login } = useAuth();
  const [userName, setUserName] = useState<string | undefined>(params.userName);

  const { data: churchSetting, isLoading } = useQuery({
    enabled: !!params.userName || !!userName,
    queryKey: ["getChurchSettings"],
    queryFn: () => {
      return getChurchSettings({ userCode: params.userName ?? userName });
    },
    onSuccess: (data) => {
      if (data) {
        setUserAndDataLocal(data);
      } else {
        removeUserLocal();
      }
    },
    onError: () => {
      removeUserLocal();
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const setUserAndDataLocal = (data: object) => {
    queryClient.clear();
    queryClient.setQueryData(["getChurchSettings"], data);
    login(params.userName ?? userName ?? "");
  };

  const removeUserLocal = () => {
    localStorage.removeItem("biblical.userNameLocal");
    showModalUser();
  };

  const isThereAUser = (userNameLocal: string | null) => {
    return !userName && !userNameLocal;
  };

  const showModalUser = async () => {
    const data = await Swal.fire({
      allowOutsideClick: false,
      title: "Ingresar código de usuario",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: false,
      confirmButtonText: "Continuar",
    });
    if (data.value !== "") {
      window.location.replace(`/${data.value}`);
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    (async () => {
      const userNameLocal = localStorage.getItem("biblical.userNameLocal");
      if (isThereAUser(userNameLocal)) {
        showModalUser();
      } else if (userNameLocal) {
        setUserName(userNameLocal);
      }
    })();
  }, [userName]);

  if (isLoading) return <Loading />;

  const message = (msg: string, height: string) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height,
          alignItems: "center",
        }}
      >
        <h4 className="text-lg font-semibold">{msg}</h4>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>BIBLICAL HORIZONS TRAVEL</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {userName ? (
          <>
            <SectionHeroArchivePage
              currentPage="Stays"
              currentTab="Stays"
              className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
              churchSetting={churchSetting}
            />

            <SectionGridFilterCard
              className="pb-24 lg:pb-28"
              messageRender={(msg: string, height: string) =>
                message(msg, height)
              }
              userId={churchSetting.userId}
            />
          </>
        ) : (
          message("Usuario inválido", "100vh")
        )}
      </div>
    </div>
  );
};

export default ListingStayPage;
