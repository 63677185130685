import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getPackages } from "services/package";


const usePackage = (userId) => {
    const [filter, setFilter] = useState({ userId });

    const { data: packages, isLoading } = useQuery({
        enabled: !!filter && !!userId,
        queryKey: ['getPackage', filter],
        queryFn: () => {
            return getPackages(filter)
        }
    })

    const filterPackage = (data) => {
        setFilter({
            userId,
            ...data
        })
    }

    return {
        packages,
        isLoading,
        filterPackage
    }
}

export default usePackage;
