import React, { FC, useEffect, useState } from "react";
import DateInfo from "./DateInfo";

export interface SectionGridFilterCardProps {
  className?: string;
  dates?: any;
  setSelectedDetail?: any;
}

const SectionDatePrices: FC<SectionGridFilterCardProps> = ({
  className = "",
  dates,
  setSelectedDetail,
}) => {
  const [isOpen, setIsOpen] = useState(dates[0]?.Id);

  useEffect(() => {
    (() => {
      setSelectedDetail(dates?.filter((item: any) => item.Id === isOpen)[0]);
    })();
  }, [isOpen]);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <div className="lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
        {dates.map((item: any, index: number) => (
          <DateInfo
            key={index}
            data={item}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionDatePrices;
