import { createContext, useContext, useState } from "react"

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [userNameLocal, setUserNameLocal] = useState(localStorage.getItem("biblical.userNameLocal"));

    const logout = () => {
        setUserNameLocal(undefined);
        localStorage.removeItem("biblical.userNameLocal")
    }

    const login = (value) => {
        setUserNameLocal(value);
        localStorage.setItem("biblical.userNameLocal", value)
    }

    return (
        <AuthContext.Provider
            value={{
                userNameLocal,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
};
