import Label from "components/Label/Label";
import { ErrorMessage } from "formik";
import { FC } from "react";
import { Error } from "shared/Error";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";

export interface CheckOutPageProps {
  props: any;
  payment: any;
}

const getYears = () => {
  const year = new Date().getFullYear();
  const arrayYears = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < 15; index++) {
    arrayYears.push(year + index);
  }
  return arrayYears;
};

const PayOffline: FC<CheckOutPageProps> = ({ props, payment }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <h5
        style={{
          textAlign: "center",
          width: "100%",
          marginBlock: "15px",
          color: "#4b5563",
        }}
      >
        Al seleccionar este método de pago se le cobrará 3.5%
      </h5>

      <div style={{ flexDirection: "column", padding: "5px", width: "50%" }}>
        <Label>Número TC</Label>
        <Input
          type="text"
          name={`payment.offline.cardNumber`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={payment.offline.cardNumber}
        />
        <ErrorMessage name={`payment.offline.cardNumber`} render={Error} />
      </div>
      <div style={{ flexDirection: "column", padding: "5px", width: "50%" }}>
        <Label>Año de expiración</Label>
        <Select
          name={`payment.offline.expirationYear`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={payment.offline.expirationYear}
          defaultValue={payment.offline.expirationYear}
        >
          {getYears().map((val) => (
            <option value={val}>{val}</option>
          ))}
        </Select>
        <ErrorMessage name={`payment.offline.expirationYear`} render={Error} />
      </div>
      <div style={{ flexDirection: "column", padding: "5px", width: "50%" }}>
        <Label>Mes de expiración</Label>
        <Select
          name={`payment.offline.expirationMonth`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={payment.offline.expirationMonth}
          defaultValue={payment.offline.expirationMonth}
        >
          {Array(12)
            .fill(2)
            .map((val, id) => (
              <option value={id + 1}>{id + 1}</option>
            ))}
        </Select>
        <ErrorMessage name={`payment.offline.expirationMonth`} render={Error} />
      </div>
      <div style={{ flexDirection: "column", padding: "5px", width: "50%" }}>
        <Label>Código de seguridad (CVV)</Label>
        <Input
          type="text"
          name={`payment.offline.securityCode`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={payment.offline.securityCode}
        />
        <ErrorMessage name={`payment.offline.securityCode`} render={Error} />
      </div>
      <div style={{ flexDirection: "column", padding: "5px", width: "50%" }}>
        <Label>Nombre</Label>
        <Input
          type="text"
          name={`payment.offline.name`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={payment.offline.name}
        />
        <ErrorMessage name={`payment.offline.name`} render={Error} />
      </div>
      <div style={{ flexDirection: "column", padding: "5px", width: "50%" }}>
        <Label>Apellido</Label>
        <Input
          type="text"
          name={`payment.offline.lastName`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={payment.offline.lastName}
        />
        <ErrorMessage name={`payment.offline.lastName`} render={Error} />
      </div>
      <div
        className="space-y-2"
        style={{ flexDirection: "column", padding: "5px", width: "50%" }}
      >
        <Label>Número de documento</Label>
        <Input
          type="text"
          name={`payment.offline.documentNumber`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={payment.offline.documentNumber}
        />
        <ErrorMessage name={`payment.offline.documentNumber`} render={Error} />
      </div>
      <div
        className="space-y-2"
        style={{ flexDirection: "column", padding: "5px", width: "50%" }}
      >
        <Label>Télefono</Label>
        <Input
          type="text"
          name={`payment.offline.phone`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={payment.offline.phone}
        />
        <ErrorMessage name={`payment.offline.phone`} render={Error} />
      </div>
      <div
        className="space-y-2"
        style={{ flexDirection: "column", padding: "5px", width: "50%" }}
      >
        <Label>Correo eléctronico</Label>
        <Input
          type="email"
          name={`payment.offline.email`}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={payment.offline.email}
        />
        <ErrorMessage name={`payment.offline.email`} render={Error} />
      </div>
    </div>
  );
};

export default PayOffline;
