import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getChurchSettings } from "services/churchSetting";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  subTitle?: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Biblical Horizons Travel Inc",
    menus: [{ href: "", label: "8725 NW 18th Terrace Ste 303 Doral FL 33172" }],
  },
  {
    id: "2",
    title: "Información",
    menus: [{ href: "#", label: "Politicas de privacidad" }],
  },
];

const Footer: React.FC = () => {
  const params = useParams();

  const { data: churchSetting } = useQuery({
    enabled: !!params.userId,
    queryKey: ["getChurchSettings"],
    queryFn: () => {
      return getChurchSettings({ userCode: params.userId });
    },
    retry: false,
  });

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <h2
          className="font-semibold text-neutral-700 dark:text-neutral-200"
          style={{ marginTop: "10px" }}
        >
          {menu.subTitle}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              {item.href !== "" ? (
                <a
                  key={index}
                  className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                  href={item.href}
                >
                  {item.label}
                </a>
              ) : (
                <p className="text-neutral-6000 dark:text-neutral-300">
                  {item.label}
                </p>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-8 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div
            className="col-span-2 md:col-span-1"
            style={{ textAlign: "center" }}
          >
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
        {churchSetting &&
          renderWidgetMenuItem(
            {
              id: "3",
              title: churchSetting.name,
              subTitle: "Ubicación",
              menus: [
                {
                  href: "",
                  label: (
                    <>
                      {`${churchSetting.city.name}, ${churchSetting.city.state.name}`}
                      <br />
                      {`${churchSetting.address}, ${churchSetting.zipCode}`}
                    </>
                  ),
                },
              ],
            },
            3
          )}
      </div>
    </div>
  );
};

export default Footer;
