import instanceApi, { getToken } from "./config";

export const postBooking = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}Booking`;
  let token = undefined;
  if (
    localStorage.getItem("tokenCh") !== null &&
    localStorage.getItem("tokenCh") !== ""
  ) {
    token = localStorage.getItem("tokenCh");
  } else {
    token = await getToken();
  }

  const result = await instanceApi(token).post(url, data);
  return result.data;
};
