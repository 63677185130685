import { WAYTOPAY_TC } from "data/booking";
import * as Yup from "yup";

export const SignupSchema = Yup.object().shape({
    passengers: Yup.array().of(
        Yup.object().shape({
            name: Yup.string()
                .max(20, "Must be at most 20 characters")
                .required("Required field"),
            lastName: Yup.string()
                .max(20, "Must be at most 20 characters")
                .required("Required field"),
            birthDate: Yup.string().required("Required field"),
            nationality: Yup.string().required("Required field"),
            documentType: Yup.string().required("Required field"),
            documentNumber: Yup.string()
                .max(20, "Must be at most 20 characters")
                .required("Required field"),
            gender: Yup.string().required("Required field"),
            email: Yup.string().email("Invalid email").required("Required field"),
        })
    ),
    wayToPay: Yup.number().required("Required field"),
    payment: Yup.object().when("wayToPay", {
        is: WAYTOPAY_TC,
        then: (schema) =>
            schema.shape({
                offline: Yup.object().shape({
                    cardNumber: Yup.string()
                        .typeError("You must specify a number")
                        .length(16, "Must be exactly 16")
                        .matches(
                            /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}| 222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/,
                            {
                                excludeEmptyString: true,
                                message: "Invalid card number",
                            }
                        )
                        .required("Required field"),
                    expirationYear: Yup.number().required("Required field"),
                    expirationMonth: Yup.number().required("Required field"),
                    securityCode: Yup.number()
                        .typeError("you must specify a number")
                        .test(
                            "len",
                            "Must be exactly 3 characters",
                            (val) => val?.toString().length === 3
                        )
                        .required("Required field"),
                    name: Yup.string()
                        .max(20, "Must be at most 20 characters")
                        .required("Required field"),
                    lastName: Yup.string()
                        .max(20, "Must be at most 20 characters")
                        .required("Required field"),
                    documentNumber: Yup.string()
                        .max(20, "Must be at most 20 characters")
                        .required("Required field"),
                    phone: Yup.string().required("Required field"),
                    email: Yup.string()
                        .email("Email must be a valid email")
                        .required("Required field"),
                }),
            }),
    }),
    email: Yup.string().email("Invalid email").required("Required field"),
});