import { Tab } from "@headlessui/react";
import { WAYTOPAY_TC, WAYTOPAY_TF } from "data/booking";
import { useFormikContext } from "formik";
import { FC, Fragment } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import PayOffline from "./PayOffline";
import Paypal from "./Paypal";

export interface Props {
  props: any;
  payment: any;
  amount: string;
}
const PayWith: FC<Props> = ({ props, payment, amount }) => {
  const { setFieldValue } = useFormikContext();
  const changePay = (type: number) => {
    console.log(type);
    setFieldValue("wayToPay", type);
  };
  return (
    <div>
      <h3 className="text-2xl font-semibold">Pagar con</h3>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

      <div className="mt-6">
        <Tab.Group>
          <Tab.List className="flex my-5">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  onClick={() => changePay(WAYTOPAY_TF)}
                  className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                    selected
                      ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                      : "text-neutral-6000 dark:text-neutral-400"
                  }`}
                >
                  PayPal
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  onClick={() => changePay(WAYTOPAY_TF)}
                  className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                    selected
                      ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                      : "text-neutral-6000 dark:text-neutral-400"
                  }`}
                >
                  Transferencia
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  onClick={() => changePay(WAYTOPAY_TF)}
                  className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                    selected
                      ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                      : "text-neutral-6000 dark:text-neutral-400"
                  }`}
                >
                  Zelle
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  onClick={() => changePay(WAYTOPAY_TC)}
                  className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                    selected
                      ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                      : "text-neutral-6000 dark:text-neutral-400"
                  }`}
                >
                  Tarjeta de crédito
                </button>
              )}
            </Tab>
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel className="space-y-5">
              <Paypal amount={Number(amount) * 0.035 + Number(amount)} />
            </Tab.Panel>
            <Tab.Panel className="space-y-5">
              <h5
                style={{
                  textAlign: "center",
                  width: "100%",
                  marginBlock: "15px",
                  color: "#4b5563",
                }}
              >
                Para realizar pagos por transferencia al Banco Chase <br />
                <span style={{ fontSize: "17px" }}>
                  <b>Número de cuenta: 981801076</b>
                  <br />
                  <b>Número de ruta: 267084131</b>
                </span>
                <br />
                enviar el respectivo comprobante al correo{" "}
                <a href="mailto:info@biblicalhorizonstravel.com">
                  info@biblicalhorizonstravel.com
                </a>
              </h5>
            </Tab.Panel>
            <Tab.Panel className="space-y-5">
              <h5
                style={{
                  textAlign: "center",
                  width: "100%",
                  marginBlock: "15px",
                  color: "#4b5563",
                }}
              >
                Realiza tu pago a través de Zelle al correo{" "}
                <a href="mailto:info@biblicalhorizonstravel.com">
                  info@biblicalhorizonstravel.com
                </a>
                , luego enviar el respectivo comprobante.
              </h5>
            </Tab.Panel>
            <Tab.Panel className="space-y-5">
              <PayOffline props={props} payment={payment} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <div className="pt-8">
          <ButtonPrimary type="submit">Confirmar y pagar</ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default PayWith;
