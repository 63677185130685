import { useQuery } from "@tanstack/react-query";
import { getCountries } from "services/country";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const UseCountry = () => {
  const { isLoading, data: countries } = useQuery({
    queryKey: ['getCountries'],
    queryFn: getCountries,
    onError: (error) => {
      Toast.fire({
        icon: "error",
        title: error,
      });
    },
  });
  return {
    countries,
    isLoading,
  };
};

export default UseCountry;
