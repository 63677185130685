import { instaceApiBiblical } from "./config"

export const getPackages = async (data) => {
    const result = await instaceApiBiblical().post("/package/get", data);
    if (result.status === 200) {
        const response = result.data.data.map(item => {
            return {
                ...item,
                galleryImgs: item.resource ? item.resource.map(item => {
                    return `${item.path}?auto=compress&cs=tinysrgb&dpr=1&w=500`
                }) : [],
                href: `/package-detail/${item.id}`,
            }
        })
        return response
    }
    return result.data
}