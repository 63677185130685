import { FC, useState } from "react";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import NcImage from "shared/NcImage/NcImage";
import NcModal from "shared/NcModal/NcModal";
import PeopleForm from "./PeopleForm";
import { useLocation } from "react-router-dom";
import UseBooking from "hooks/useBooking";
import Loading from "shared/Loader";
import { WAYTOPAY_TC } from "data/booking";
import { ErrorMessage, Formik } from "formik";
import { Error } from "shared/Error";
import PayWith from "./PayWith";
import { reqBooking } from "utils/requests";
import { SignupSchema } from "utils/validationsSchema";
import { initValuesFormCheckout } from "utils/initialValues";
export interface CheckOutPageProps {
  className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const location = useLocation();
  const { createBooking } = UseBooking();
  const [data] = useState(location.state);
  const [initialValues] = useState(initValuesFormCheckout(data));

  const onSubmit = async (values: any) => {
    const wayToPayId = values.wayToPay;
    const payment =
      wayToPayId === WAYTOPAY_TC
        ? values.payment.offline
        : values.payment.transfer;
    payment.amount = data.total;
    /* if (wayToPay === "3") {
      await offlineSchema.isValid(values.payment.offline);
      console.log(await offlineSchema.isValid(values.payment.offline));
    }
    console.log(values); */

    const req = reqBooking(values, payment, data, wayToPayId);
    createBooking.mutate(req);
  };

  if (createBooking.isLoading) {
    return <Loading />;
  }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src="https://cdn1.visiontravel.net/Images/church-package/1.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-base font-medium mt-1 block">
                {data.data.title}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Detalle del precio</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Precio por persona</span>
            <span>
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(data?.price)}
            </span>
          </div>
          {/*<div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
            </div>*/}

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(data?.total)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSendTickets = (props: any) => {
    return (
      <>
        <div
          className="border-b border-neutral-200 dark:border-neutral-700"
          style={{ marginBlock: "25px" }}
        />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <h5
            className="text-2xl font-semibold"
            style={{
              flexDirection: "column",
              paddingBlock: "15px",
              width: "100%",
              marginBottom: "15px",
            }}
          >
            ¿Dónde enviamos las entradas?
          </h5>
          <div
            style={{
              flexDirection: "column",
              padding: "5px",
              width: "100%",
            }}
          >
            <Label>Correo electrónico</Label>
            <Input
              type="email"
              name="email"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.email}
            />
            <ErrorMessage name="email" render={Error} />
          </div>
          <NcModal
            renderTrigger={(openModal) => (
              <span
                onClick={() => openModal()}
                className="block lg:hidden underline  mt-1 cursor-pointer"
              >
                View booking details
              </span>
            )}
            renderContent={renderSidebar}
            modalTitle="Booking details"
          />
        </div>
        <div
          className="border-b border-neutral-200 dark:border-neutral-700"
          style={{ marginBlock: "25px" }}
        />
      </>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirmar y pagar
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <Formik
          initialValues={initialValues}
          validationSchema={SignupSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div>
                <div>
                  {props.values.passengers.map(
                    (passenger: any, index: number) => {
                      return (
                        <>
                          <PeopleForm
                            index={index}
                            props={props}
                            passenger={passenger}
                          />
                        </>
                      );
                    }
                  )}
                  {renderSendTickets(props)}
                </div>
                <PayWith
                  props={props}
                  payment={props.values.payment}
                  amount={data.total}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPage;
