import { Popover, Transition } from "@headlessui/react";
import { FC, Fragment } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

export interface FlightCardProps {
  className?: string;
  data: {
    Id: string;
    StartDate: string;
    EndDate: string;
    Value: string;
    AdultQuantity: number;
    ChildrenQuantity: number;
    InfantQuantity: number;
    Remark: string;
  };
  isOpen: string;
  setIsOpen: any;
}

const DateInfo: FC<FlightCardProps> = ({
  className = "",
  data,
  isOpen,
  setIsOpen,
}) => {
  const PopoverRemark = (remark: string) => {
    return (
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button
              className={`${open ? "" : "text-opacity-90"}
                  group py-2 rounded-md text-sm sm:text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <div className={` inline-flex items-center `} role="button">
                <InformationCircleIcon
                  className={`ml-2 h-10 w-10 text-neutral-700 group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                  aria-hidden="true"
                />
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={"absolute"}>
                <div className="rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="bg-white p-7 ">{remark}</div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6  bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
      style={
        isOpen === data.Id
          ? { borderColor: "#7000ea73", cursor: "pointer" }
          : { borderColor: "transparent", cursor: "pointer" }
      }
      onClick={() => setIsOpen(data.Id)}
    >
      <div className={`${className}`} data-nc-id="FlightCard">
        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* TIME - NAME */}
          <div className="flex-[6]">
            <div className="font-medium text-lg">Fecha Salida</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.StartDate}
            </div>
          </div>

          {/* TIMME */}
          <div className="flex-[6] whitespace-nowrap">
            <div className="font-medium text-lg"> Fecha Regreso</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.EndDate}
            </div>
          </div>

          {/* TYPE */}
          <div className="flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">Pasajeros</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.AdultQuantity} Adultos
            </div>
          </div>

          {/* PRICE */}
          <div className="flex-[2] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-6000">
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(Number(data.Value))}
              </span>
            </div>
          </div>

          {data.Remark && (
            <div className="flex-[2]">{PopoverRemark(data.Remark)}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateInfo;
