import { instaceApiBiblical } from "./config";

export const getCities = async (data) => {
    const result = await instaceApiBiblical().post("city/get", data);
    return result.data.data;
}

export const getCitiesByText = async (text) => {
    const result = await instaceApiBiblical().get(`city/getByText/${text}`);
    return result.data.data;
}