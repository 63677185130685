import axios from "axios";

export const instaceApiBiblical = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BIBLICAL_URL,
    headers: {
      "Accept-Language": "es"
    },
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    }
  );

  return instance;
}

export const instanceApi = (_token) => {
  let headers = {};
  let token = null;

  if (_token) {
    token = _token;
  } else if (
    localStorage.getItem("tokenCh") !== null &&
    localStorage.getItem("tokenCh") !== ""
  ) {
    token = localStorage.getItem("tokenCh");
  }

  if (token) {
    headers = {
      "Accept-Language": "es",
      Authorization: `Bearer ${token}`,
    };
  }

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.removeItem("tokenCh");
          getToken();
          return instance;
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const getToken = async () => {
  const result = await instanceApi().post(`Token`, {
    username: "a.gomez@visiontravel.net",
    password: "4n4M4r14.321",
  });
  if (result.data.token) {
    localStorage.setItem("tokenCh", result.data.token);
  }
};

export default instanceApi;
