import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import ModalSelectDate from "components/ModalSelectDate";
import ModalSelectGuests from "components/ModalSelectGuests";
import React, { useState, FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalReserveMobile from "./ModalReserveMobile";

export interface Props {
  total?: string;
  reserve?: () => any;
  date?: Date;
  selectedDate: DateRage;
  setSelectedDate: (date: DateRage) => void;
  passengerData: {
    guestAdults: number;
  };
  setGuestAdults: (value: number) => void;
}

const MobileFooterSticky: FC<Props> = ({
  total,
  reserve,
  selectedDate,
  setSelectedDate,
  passengerData,
  setGuestAdults,
}) => {
  const [guestsState, setGuestsState] = useState<GuestsObject>({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  });

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-20">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">{total}</span>
          <ModalSelectDate
            defaultValue={selectedDate}
            onSelectDate={setSelectedDate}
            renderChildren={({ defaultValue, openModal }) => (
              <span
                // onClick={openModal}
                className="block text-sm font-medium"
              >
                {converSelectedDateToString(selectedDate)}
              </span>
            )}
          />
          <ModalSelectGuests
            setGuestAdults={setGuestAdults}
            guestAdults={passengerData.guestAdults}
            renderChildren={({ guestAdults, openModal }) => (
              <span
                onClick={openModal}
                className="block xl:text-lg font-semibold underline"
              >
                {guestAdults} Pasajeros
              </span>
            )}
          />
        </div>
        <ModalReserveMobile
          defaultGuests={guestsState}
          defaultDate={selectedDate}
          onChangeDate={setSelectedDate}
          onChangeGuests={setGuestsState}
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              onClick={reserve}
            >
              Reservar
            </ButtonPrimary>
          )}
        />
      </div>
    </div>
  );
};

export default MobileFooterSticky;
