import { FC } from "react";
import StayCard from "components/StayCard/StayCard";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import usePackage from "hooks/usePackage";
import Loading from "shared/Loader";

export interface SectionGridFilterCardProps {
  className?: string;
  messageRender: (msg: string, height: string) => JSX.Element;
  userId?: string;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  messageRender,
  userId,
}) => {
  const { packages, filterPackage, isLoading } = usePackage(userId);

  if (isLoading) return <Loading />;
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <div
        className="z-10 lg:-mt-40 xl:-mt-56 w-full"
        style={{ paddingBottom: "40px" }}
      >
        <HeroSearchForm
          filterPackage={(value: object) => filterPackage(value)}
        />
      </div>
      {/*<Heading2 />*/}

      {/*<div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>*/}

      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {packages?.length === 0 || !packages
          ? messageRender("Sin Resultados", "100%")
          : packages?.map((stay: any) => (
              <StayCard key={stay.id} packageData={stay} />
            ))}
      </div>
      {/* <StayCard key={data[0].id} data={data[0]} /> */}
      <div className="flex mt-16 justify-center items-center">
        {/* <Pagination /> */}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
